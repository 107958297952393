<template>
  <header
    ref="pageHeader"
    :class="[
      'header',
      displayPartnerLogo && partnerLogo ? 'header--partner' : '',
      isMenuOpen ? 'is-menu' : '',
      isFixed ? 'is-fixed' : '',
      'extendedBg'
    ]"
  >
    <nuxt-link
      class="header__logo"
      :to="indexLink"
      @click="indexLinkClick(indexLink)"
    >
      <client-only>
        <template v-if="displayPartnerLogo">
          <div
            v-if="partnerLogo"
            class="partnerLogo"
          >
            <img
              :src="partnerLogo"
              alt="Partner logo"
              class="icon icon--cashtec"
            >
          </div>
          <div
            v-if="partnerLogo"
            class="app__title app__title--h3 color--grayTone4"
          >
            +
          </div>
        </template>
      </client-only>
      <img :src="partnerStore.cashtecLogo" class="icon icon--cashtec" alt="CashTec logo">
    </nuxt-link>
    <div class="header__mobileActions">
      <v-btn
        v-if="authStore.isLoggedIn"
        class="iconWrapper iconWrapper--user flex-center cursor-pointer"
        :to="localePath({ name: 'profile' })"
        @click="headerIconAction('user')"
      >
        <img src="~/assets/svg/user-alt.svg?url" class="icon icon--user" alt="...">
      </v-btn>
      <v-btn
        v-if="authStore.isLoggedIn && authStore.isPremiumPartner"
        class="iconWrapper iconWrapper--company flex-center cursor-pointer"
        :to="localePath({name: 'profile-premium'})"
        @click="headerIconAction('company')"
      >
        <img src="~/assets/svg/company.svg?url" class="icon icon--company" alt="...">
      </v-btn>
      <div
        :class="['hamburger', isMenuOpen ? 'is-open' : '', ' d-flex cursor-pointer']"
        @click="isMenuOpen = !isMenuOpen"
      >
        <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <line x1="0" y1="11" x2="32" y2="11" stroke-width="3" />
            <line x1="0" y1="21" x2="32" y2="21" stroke-width="3" />
          </g>
          <g>
            <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
            <line x1="0" y1="16" x2="32" y2="16" stroke-width="3" />
          </g>
        </svg>
      </div>
    </div>
    <div class="header__divider" />
    <nav class="header__nav font-dm-sans">
      <slot name="middleBtnBar">
        <v-btn
          v-if="!authStore.isLoggedIn"
          variant="text"
          size="large"
          @click="hiwBtnAction"
        >
          {{ t("layout.menu.how_it_works") }}
        </v-btn>
        <v-btn
          v-if="!partnerStore.getBuyoutPartner"
          variant="text"
          size="large"
          :to="localePath({name: 'bonuses'})"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.bonuses") }}
        </v-btn>
        <v-btn
          v-else-if="partnerStore.getBuyoutPartner && partnerStore?.getBuyoutPartner?.has_bonus_master"
          variant="text"
          size="large"
          :to="bonusesLink"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.bonuses") }}
        </v-btn>
        <v-btn
          v-if="!authStore.isLoggedIn && !partnerStore.getBuyoutPartner"
          variant="text"
          size="large"
          :to="localePath({name: 'premium'})"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.partner_program") }}
        </v-btn>
        <!--        # 91673 - remove ambassador program -->
        <!--        <v-btn-->
        <!--          v-if="!authStore.isLoggedIn && !partnerStore.getBuyoutPartner"-->
        <!--          variant="text"-->
        <!--          size="large"-->
        <!--          :to="localePath({name: 'ambassador'})"-->
        <!--          @click="isMenuOpen = false"-->
        <!--        >-->
        <!--          {{ t("layout.menu.ambassador_program") }}-->
        <!--        </v-btn>-->
        <v-btn
          variant="text"
          size="large"
          @click="faqBtnAction"
        >
          {{ t("layout.menu.question") }}
        </v-btn>
        <v-btn
          variant="text"
          size="large"
          :to="contactLink"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.contact") }}
        </v-btn>
      </slot>
    </nav>
    <div class="header__actions">
      <common-language-switcher />
      <slot name="rightBtnBar">
        <v-btn
          v-if="!authStore.isLoggedIn"
          id="login_btn"
          class="mbtn mbtn--trans"
          size="large"
          :to="localePath({ name: 'login' })"
          @click="isMenuOpen = false"
        >
          {{ t("layout.menu.log_in") }}
        </v-btn>

        <v-btn
          v-if="authStore.isLoggedIn && authStore.data !== null"
          :class="authStore.isLoggedIn && activeNews ? 'mbtn mbtn--trans is-notification' : 'mbtn mbtn--trans'"
          size="large"
          :to="localePath({name: 'profile'})"
          @click="isMenuOpen = false"
        >
          {{ authStore.data!!.username }}
        </v-btn>
        <v-btn
          v-if="authStore.isLoggedIn && authStore.isPremiumPartner"
          class="mbtn mbtn--trans"
          size="large"
          :to="localePath({name: 'profile-premium'})"
          @click="isMenuOpen = false"
        >
          {{ authStore.data!.partner.name }}
        </v-btn>
        <v-btn
          class="mbtn mbtn--blue mbtn--buyout"
          size="large"
          :to="buyoutLinkRef"
          @click="isMenuOpen = false"
        >
          <template v-if="authStore.isPartner || authStore.isPremiumPartner">
            {{ t("layout.menu.price_the_device") }}
          </template>
          <template v-else>
            {{ t("layout.menu.sell_device") }}
          </template>
        </v-btn>
      </slot>
    </div>
  </header>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const partnerStore = usePartnerStore();
const localePath = useLocalePath();
const { t } = useI18n();
const profileStore = useProfileStore();
const { activeNews } = storeToRefs(profileStore);
const searchParams = useUrlSearchParams("history");
const buyoutStore = useBuyoutStore();
const { indexLinkClick, indexLink, buyoutLink, partnerLogo, isMenuOpen, isFixed, pageHeader } = useHeader();

if (searchParams.refId) {
  buyoutStore.buyoutReferralId = searchParams.refId as string;
}

const contactLink = computed<string>(() => {
  const partner = partnerStore.getBuyoutPartner;
  if (partner?.seo_name) {
    if(partner?.is_manufacturer_page) {
      const param = partner?.parent?.seo_name || partner?.seo_name;
      return localePath({ name: "partner", params: { partner: param}, query: { to: "contact" }});
    } else {
      return localePath({ name: "partner-contact", params: { partner: partner.seo_name } });
    }
  } else {
    return localePath({ name: "contact" });
  }
});

const bonusesLink = computed<string>(() => {
  const partner = partnerStore.getBuyoutPartner;

  if (partner?.is_manufacturer_page) {
    const param = partner?.parent?.seo_name || partner?.seo_name;
    return localePath({name: "partner", params: { partner: param}, query: { to: "bonuses"}});
  }
  return localePath({name: "partner-bonuses", params: { partner: partner?.seo_name }});
});

const displayPartnerLogo = computed<boolean>(() => (partnerStore.getBuyoutPartner !== null));
const buyoutLinkRef = computed<string>(() => { return buyoutLink.value; });

onMounted(() => {
  if (authStore.isLoggedIn) {
    profileStore.getNews();
  }
  if (pageHeader.value) { isFixed.value = window.scrollY > pageHeader.value.offsetTop; }

  window.document.onscroll = () => {
    if (pageHeader.value) { isFixed.value = window.scrollY > pageHeader.value.offsetTop; }
  };
});

function headerIconAction (modifier: string) {
  if (document.querySelector(`.header__mobileActions .iconWrapper--${modifier}.v-btn--active`)) {
    document.querySelector(".pMenu")?.classList.toggle("is-open");
  }
}

function faqBtnAction () {
  isMenuOpen.value = false;
  const partner = partnerStore?.getBuyoutPartner;

  if (document.querySelector(".faq")) {
    useGoTo(".faq", { offset: -100 });
  } else {
    if(partner?.is_manufacturer_page) {
      const param = partner?.parent?.seo_name || partner?.seo_name;
      navigateTo(localePath({ name: "partner", params: { partner: param}, query: { to: "faq" }}));

    } else {
      navigateTo(localePath({ name: "questions" }));
    }
  }
}

function hiwBtnAction () {
  isMenuOpen.value = false;

  if (document.querySelector(".hiw")) {
    useGoTo(".hiw", { offset: -100 });
  } else {
    const hiwPathName = partnerStore.getBuyoutPartner ? "partner" : "index";

    navigateTo(localePath({ name: hiwPathName, params: { partner: partnerStore.getBuyoutPartner?.seo_name } }));
  }
}
</script>
